import { ApiClient } from "./clients";
import { BearerToken } from "./models/auth/BearerToken";
import {EsiaRedirect} from "@/utils/api-client/models/auth/EsiaRedirect";

type ServerBearerToken = {
  accessToken: string;
  accessTokenExpiredAt: string;
  refreshToken: string;
  refreshTokenExpiredAt: string;
};

export class AuthManager {
  constructor(private readonly apiClient: ApiClient) {}

  async login(username: string, password: string): Promise<BearerToken> {
    const data = await this.apiClient.post("/login", {
      username,
      password
    });
    return AuthManager.createBearerToken(data);
  }

  async autologin(token: string): Promise<BearerToken> {
    const data = await this.apiClient.post("/autologin", { token });
    return AuthManager.createBearerToken(data);
  }

  async refresh(token: string): Promise<BearerToken> {
    const data = await this.apiClient.post("/refresh", { token });
    return AuthManager.createBearerToken(data);
  }

  async esiaConnect(): Promise<EsiaRedirect> {
    return await this.apiClient.post("/esia-connect");
  }

  async esiaLogin(code: string, state: string): Promise<BearerToken> {
    return await this.apiClient.post("/esia-login", {code, state});
  }

  private static createBearerToken(data: ServerBearerToken): BearerToken {
    return new BearerToken(
      data.accessToken,
      new Date(data.accessTokenExpiredAt),
      data.refreshToken,
      new Date(data.refreshTokenExpiredAt)
    );
  }
}
