import { ApiClient } from "./clients";
import { UrlResult } from "./models/common/UrlResult";
import { buildQuery } from "./utils";

type ExportAgentReportFilter = {
  dateFrom: string;
  dateTo: string;
}

type ExportNdflReportFilter = {
  year: number;
}

export class ReportManager {
  constructor(private readonly apiClient: ApiClient) {}

  exportAgentReport(filter: ExportAgentReportFilter): Promise<UrlResult> {
    return this.apiClient.get(`/agent-report?${buildQuery(filter, "filter")}`);
  }

  exportAgentReport2020(filter: ExportAgentReportFilter): Promise<UrlResult> {
    return this.apiClient.get(`/agent-report-2020?${buildQuery(filter, "filter")}`);
  }

  exportNdflReport(filter: ExportNdflReportFilter): Promise<UrlResult> {
    return this.apiClient.get(`/ndfl-report?${buildQuery(filter, "filter")}`);
  }

  exportIncomeReport(filter: ExportAgentReportFilter): Promise<UrlResult> {
    return this.apiClient.get(`/income-report?${buildQuery(filter, "filter")}`);
  }
}
