export default [
  {
    path: "login",
    component: (): Promise<{}> => import("../components/Login.vue"),
    name: "login",
    meta: { requiresAuth: false }
  },
  {
    path: "autologin",
    component: (): Promise<{}> => import("../components/Autologin.vue"),
    name: "autologin",
    meta: { requiresAuth: false }
  },
  {
    path: "outdated-password",
    component: (): Promise<{}> => import("../components/OutdatedPassword.vue"),
    name: "outdated-password"
  },
  {
    path: "callback-login",
    component: (): Promise<{}> => import("../components/CallbackLogin.vue"),
    name: "callback-login",
    meta: { requiresAuth: false }
  }
];
