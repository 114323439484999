import { ApiClient } from "./clients";
import { Money } from "./models/common/Money";
import { Phone } from "./models/common/Phone";
import { PhoneResult } from "./models/common/PhoneResult";
import { UuidResult } from "./models/common/UuidResult";
import { Profile } from "./models/user/Profile";

export class ProfileManager {
  constructor(private readonly apiClient: ApiClient, private readonly useGetCache: boolean = false) {}

  get(): Promise<Profile> {
    return this.apiClient.get("", this.useGetCache);
  }

  getBalance(): Promise<Money> {
    return this.apiClient.get("/balance");
  }

  setFirstPassword(password: string): Promise<void> {
    return this.apiClient.post("/set-first-password", {password});
  }

  changePasswordRequest(): Promise<UuidResult> {
    return this.apiClient.post("/change-password-request");
  }

  changeUsernameRequest(): Promise<UuidResult> {
    return this.apiClient.post("/change-username-request");
  }

  addPhone(phone: Phone): Promise<PhoneResult> {
    return this.apiClient.post("/add-phone", {phone});
  }

  verifyPhone(phone: Phone, verificationCode: string): Promise<PhoneResult> {
    return this.apiClient.post("/verify-phone", {phone, verificationCode});
  }

  setPrimaryMarketAgreement(primaryMarketAgreement: Array<string>): Promise<void> {
    return this.apiClient.post("/set-primary-market-agreement", {primaryMarketAgreement});
  }

  confirmRules(): Promise<void> {
    return this.apiClient.post("/confirm-rules");
  }
}
